import { createSelector } from "reselect"
import { SurveyStore } from "../types"

import { getPatient, getRelatives } from "../../../services/client/selector"
import { Patient, Relative } from "../../../types/types"
import { PATH } from "../models"
import { Gender } from "../../../types/types"

import { getAge } from "../../../lib/utils"

const getSurveyId = ({ survey }: { survey: SurveyStore }): string | undefined =>
  survey?.current?.id

const getCurrentSelectedPatientId = ({
  survey,
}: {
  survey: SurveyStore
}): string => {
  let selectedPatientId: any
  selectedPatientId = survey?.answers?.find((answer) => {
    return answer.questionId === PATH.patient
  })
  if (selectedPatientId.value[0] === -1) {
    selectedPatientId = survey?.answers?.find((answer) => {
      return answer.questionId === PATH.relative
    })
  }

  return selectedPatientId?.value[0]
}

const isPregnantQuestionSet = ({
  survey,
}: {
  survey: SurveyStore
}): boolean => {
  return survey?.questions?.some((question) => question.id === PATH.pregnant)
}

// TODO : retrieve current patient age

const getCurrentPatientAge = createSelector(
  getCurrentSelectedPatientId,
  getPatient,
  getRelatives,
  (
    selectedPatientId: any,
    patient: Patient,
    relatives: Relative[]
  ): string | number | undefined => {
    if (selectedPatientId === 0) {
      return getAge(patient.birthdate)
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return getAge(selectedRelative?.birthdate)
  }
)

const getCurrentPatientFirstName = createSelector(
  getCurrentSelectedPatientId,
  getPatient,
  getRelatives,
  (
    selectedPatientId: any,
    patient: Patient,
    relatives: Relative[]
  ): string | undefined => {
    if (selectedPatientId === 0) {
      return patient.firstname
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative?.firstname
  }
)


const getCurrentPatientGender = createSelector(
  getCurrentSelectedPatientId,
  getPatient,
  getRelatives,
  (
    selectedPatientId: any,
    patient: Patient,
    relatives: Relative[]
  ): string | number | undefined => {
    if (selectedPatientId === 0) {
      return patient.sex
    }
    const selectedRelative = relatives.find(
      (relative) => relative.id === selectedPatientId
    )
    return selectedRelative?.gender
  }
)

export {
  getSurveyId,
  getCurrentSelectedPatientId,
  getCurrentPatientAge,
  getCurrentPatientFirstName,
  getCurrentPatientGender,
  isPregnantQuestionSet,
}
