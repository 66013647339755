import { INSERT_RELATIVE_PAGE } from "../../core/constants"
import { languages } from "../../lib/languages"
import { ACTION, Question, Reply } from "./types"

export const ITEMS_PER_CAROUSEL_PAGE = 6
export const SECOND_NEXT_ANSWER = 0.4
export const PATH_DEEP = ":"
export const DURATION_SLOT_MIN = 5
export const NUMBER_OF_SLOTS_BEFORE_NOW = 5
export const NUMBER_OF_SLOTS_AFTER_NOW = 5

export enum PATH {
  patient = "patient",
  relative = "patient:relative",
  doctor = "doctor",
  slot = "doctor:slot",
  inform = "inform",
  reason = "reason",
  illness = "illness",
  illness_details = "illness:details",
  allergy = "allergy",
  allergy_details = "allergy:details",
  operation = "operation",
  drug = "drug",
  pregnant = "pregnant",
}

const booleanSubReplies: Reply[] = [
  {
    title: languages.yes,
    action: ACTION.subquestion,
    value: true,
  },
  {
    title: languages.no,
    value: false,
  },
]
const booleanReplies: Reply[] = [
  {
    title: languages.yes,
    value: true,
  },
  {
    title: languages.no,
    value: false,
  },
]
const relative: Question = {
  title: languages.questionWhichRelative,
  id: PATH.relative,
  multipleReplies: false,
  replies: [],
  defaultReply: {
    title: languages.addARelative,
    value: INSERT_RELATIVE_PAGE,
    action: ACTION.redirect,
  },
}
const slot: Question = {
  title: languages.questionWhichTime,
  id: PATH.slot,
  ui: { position: "middle" },
  multipleReplies: false,
  replies: [],
}
const doctor: Question = {
  title: languages.orderForWhichDoctor,
  id: PATH.doctor,
  subquestion: slot,
  multipleReplies: false,
  confirmModal: {
    trigger: "default",
    title: "Dirigez-vous vers l'accueil",
    message:
      "Veuillez vous diriger vers l'accueil afin de savoir quel médecin va vous prendre en charge. Revenez ensuite finaliser votre enregistrement sur la borne.",
  },
  replies: [],
  defaultReply: {
    title: languages.noAppointment,
    value: undefined,
  },
}

const inform_anamnesis: Question = {
  title: languages.inform_anamnesis,
  id: PATH.inform,
  multipleReplies: false,
  replies: [],
  defaultReply: {
    title: languages.continue,
    value: -1,
  },
}

const patient: Question = {
  title: languages.questionForWho,
  id: PATH.patient,
  subquestion: relative,
  multipleReplies: false,
  replies: [],
}

export const pregnant: Question = {
  title: languages.anamnesePregnantQuestion,
  id: PATH.pregnant,
  multipleReplies: false,
  replies: booleanReplies,
}

const operation: Question = {
  title: languages.anamneseOperationsQuestion,
  id: PATH.operation,
  multipleReplies: false,
  replies: booleanReplies,
}

const drug: Question = {
  title: languages.anamneseDrugsQuestion,
  id: PATH.drug,
  multipleReplies: false,
  replies: booleanSubReplies,
}
const allergy_details: Question = {
  title: languages.anamneseAllergiesQuestionDetails,
  id: PATH.allergy_details,
  ui: { view: "carousel" },
  multipleReplies: true,
  replies: [
    {
      title: languages.drugAllergy,
      value: languages.drugAllergy,
      img: "images/allergies/picto_medicament.svg",
    },
    {
      title: languages.pollenAllergy,
      value: languages.pollenAllergy,
      img: "images/allergies/picto_pollen.svg",
    },
    {
      title: languages.foodAllergy,
      value: languages.foodAllergy,
      img: "images/allergies/picto_alimentaire.svg",
    },
    {
      title: languages.otherAllergy,
      value: languages.otherAllergy,
      img: "images/allergies/picto_autre.svg",
    },
  ],
}

const allergy: Question = {
  title: languages.anamneseAllergiesQuestion,
  subtitle: languages.anamneseAllergiesSubtitle,
  id: PATH.allergy,
  multipleReplies: false,
  subquestion: allergy_details,
  replies: booleanSubReplies,
}

const illness_details: Question = {
  title: languages.anamneseIllnessQuestionDetails,
  id: PATH.illness_details,
  multipleReplies: true,
  ui: { view: "carousel" },
  replies: [
    {
      title: languages.diabetes,
      value: languages.diabetes,
      img: "images/illnesses/picto_diabete.svg",
    },
    {
      title: languages.hypertension,
      value: languages.hypertension,
      img: "images/illnesses/picto_hypertension.svg",
    },
    {
      title: languages.cancer,
      value: languages.cancer,
      img: "images/illnesses/picto_cancer.svg",
    },
    {
      title: languages.respiratoryDisease,
      value: languages.respiratoryDisease,
      img: "images/illnesses/picto_respiration.svg",
    },
    {
      title: languages.heartDisease,
      value: languages.heartDisease,
      img: "images/illnesses/picto_hypertension.svg",
    },
    {
      title: languages.kidneyDisease,
      value: languages.kidneyDisease,
      img: "images/illnesses/picto_renal.svg",
    },
    {
      title: languages.digestiveDisease,
      value: languages.digestiveDisease,
      img: "images/illnesses/picto_digestion.svg",
    },
    {
      title: languages.infectiousDisease,
      value: languages.infectiousDisease,
      img: "images/illnesses/picto_infection.svg",
    },
    {
      title: languages.otherDisease,
      value: languages.otherDisease,
      img: "images/allergies/picto_autre.svg",
    },
  ],
}

const illness: Question = {
  title: languages.anamneseIllnessQuestion,
  subtitle: languages.anamneseIllnessSubtitle,
  id: PATH.illness,
  subquestion: illness_details,
  multipleReplies: false,
  replies: booleanSubReplies,
}

const reason: Question = {
  title: languages.anamneseReasonQuestion,
  id: PATH.reason,
  multipleReplies: false,
  ui: { view: "carousel" },
  replies: [
    {
      title: languages.covid,
      value: languages.covid,
      img: "images/reason/picto_covid.svg",
    },
    {
      title: languages.allergy,
      value: languages.allergy,
      img: "images/reason/picto_allergies.svg",
    },
    {
      title: languages.burn,
      value: languages.burn,
      img: "images/reason/picto_brulure.svg",
    },
    {
      title: languages.contraception,
      value: languages.contraception,
      img: "images/reason/picto_contraception.svg",
    },
    {
      title: languages.cystitis,
      value: languages.cystitis,
      img: "images/reason/picto_cystite.svg",
    },
    {
      title: languages.jointpain,
      value: languages.jointpainWithoutParenthesis,
      img: "images/reason/picto_jointpain.svg",
    },
    {
      title: languages.toothpain,
      value: languages.toothpain,
      img: "images/reason/picto_toothache.svg",
    },
    {
      title: languages.diarrhea_or_vomiting,
      value: languages.diarrhea_or_vomiting,
      img: "images/reason/picto_diarrhee.svg",
    },
    {
      title: languages.back_pain,
      value: languages.back_pain,
      img: "images/reason/picto_douleur_lombaire.svg",
    },
    {
      title: languages.rash,
      value: languages.rash,
      img: "images/reason/picto_eruption_cutanee.svg",
    },
    {
      title: languages.tired,
      value: languages.tired,
      img: "images/reason/picto_fatigue.svg",
    },
    {
      title: languages.fever,
      value: languages.fever,
      img: "images/reason/picto_fievre.svg",
    },
    {
      title: languages.sexual_infection,
      value: languages.sexual_infection,
      img: "images/reason/picto_infection_sexuelle.svg",
    },
    {
      title: languages.sore_throat,
      value: languages.sore_throat,
      img: "images/reason/picto_mal_de_gorge.svg",
    },
    {
      title: languages.headache,
      value: languages.headache,
      img: "images/reason/picto_mal_de_tete.svg",
    },
    {
      title: languages.stomach_ache,
      value: languages.stomach_ache,
      img: "images/reason/picto_maux_de_ventre.svg",
    },
    {
      title: languages.eye_pain,
      value: languages.eye_pain,
      img: "images/reason/picto_oeil_rouge_douleur.svg",
    },
    {
      title: languages.otitis,
      value: languages.otitis,
      img: "images/reason/picto_otitis.svg",
    },
    {
      title: languages.sting,
      value: languages.sting,
      img: "images/reason/picto_sting.svg",
    },
    {
      title: languages.prescription_refill,
      value: languages.prescription_refill,
      img: "images/reason/picto_ordonnance.svg",
    },
    {
      title: languages.period_pain,
      value: languages.period_pain,
      img: "images/reason/picto_regles_douloureuse.svg",
    },
    {
      title: languages.lab_results,
      value: languages.lab_results,
      img: "images/reason/picto_resultat_laboratoire.svg",
    },
    {
      title: languages.cold_and_flu,
      value: languages.cold_and_flu,
      img: "images/reason/picto_rhume.svg",
    },
    {
      title: languages.sinus,
      value: languages.sinus,
      img: "images/reason/picto_nez_bouche.svg",
    },
    {
      title: languages.cough,
      value: languages.cough,
      img: "images/reason/picto_toux.svg",
    },
    {
      title: languages.otherReason,
      value: languages.otherReason,
      img: "images/reason/picto_autre.svg",
    },
  ],
}
export const QUESTIONS = [
  patient,
  doctor,
  inform_anamnesis,
  reason,
  illness,
  operation,
  allergy,
  pregnant,
  drug,
]

// JSON SCHEMA

export const surveyLanguages = [
  {
    id: PATH.patient,
    patient: {
      title: languages.questionForWho,
    },
    relative: {
      title: languages.questionForWho,
    },
  },
  {
    id: PATH.reason,
    patient: {
      title: languages.anamneseReasonQuestion,
    },
    relative: {
      title: languages.anamneseReasonQuestion_relative,
    },
  },
  {
    id: PATH.doctor,
    patient: {
      title: languages.orderForWhichDoctor,
      defaultReply: languages.noAppointment,
    },
    relative: {
      title: languages.orderForWhichDoctor_relative,
      defaultReply: languages.noAppointment_relative,
    },
  },
  {
    id: PATH.slot,
    patient: {
      title: languages.questionWhichTime,
    },
    relative: {
      title: languages.questionWhichTime,
    },
  },
  {
    id: PATH.pregnant,
    patient: {
      title: languages.anamnesePregnantQuestion,
    },
    relative: {
      title: languages.anamnesePregnantQuestion_relative,
    },
  },
  {
    id: PATH.operation,
    patient: {
      title: languages.anamneseOperationsQuestion,
    },
    relative: {
      title: languages.anamneseOperationsQuestion_relative,
    },
  },
  {
    id: PATH.drug,
    patient: {
      title: languages.anamneseDrugsQuestion,
    },
    relative: {
      title: languages.anamneseDrugsQuestion_relative,
    },
  },
  {
    id: PATH.allergy,
    patient: {
      title: languages.anamneseAllergiesQuestion,
      subtitle: languages.anamneseAllergiesSubtitle,
    },
    relative: {
      title: languages.anamneseAllergiesQuestion_relative,
      subtitle: languages.anamneseAllergiesSubtitle,
    },
  },
  {
    id: PATH.illness,
    patient: {
      title: languages.anamneseIllnessQuestion,
      subtitle: languages.anamneseIllnessSubtitle,
    },
    relative: {
      title: languages.anamneseIllnessQuestion_relative,
      subtitle: languages.anamneseIllnessSubtitle,
    },
  },
  {
    id: PATH.inform,
    patient: {
      title: languages.inform_anamnesis,
    },
    relative: {
      title: languages.inform_anamnesis_relative,
    },
  },
]
