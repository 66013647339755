import { find, first } from "lodash"
import {
  SET_QUESTION,
  SET_QUESTION_SAGA,
  UNSET_QUESTION,
  NEXT_QUESTION,
  PREVIOUS_QUESTION,
  REMOVE_QUESTION,
  SUBMIT_SURVEY_REQUEST,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_ERROR,
  QUESTION_SLOTS,
  CREATE_QUESTION,
  SET_CUSTOMER,
} from "./constants"
import {
  NUMBER_OF_SLOTS_AFTER_NOW,
  NUMBER_OF_SLOTS_BEFORE_NOW,
  PATH,
} from "../models"
import { Answer, ConsultationPayload, Question, Reply, Value } from "../types"

const actions = {
  create: (payload: Question) => ({
    type: CREATE_QUESTION,
    payload,
  }),

  remove: (payload: string) => ({
    type: REMOVE_QUESTION,
    payload,
  }),

  set: (replies: Reply[]) => ({
    type: SET_QUESTION,
    payload: replies,
  }),

  setSaga: (replies: Reply[]) => ({
    type: SET_QUESTION_SAGA,
    payload: replies,
  }),

  unset: (replies?: Reply[]) => ({
    type: UNSET_QUESTION,
    payload: replies,
  }),

  next: () => ({ type: NEXT_QUESTION }),

  previous: () => ({ type: PREVIOUS_QUESTION }),

  setSlots: () => ({
    type: QUESTION_SLOTS,
    payload: {
      slotsBeforeNow: NUMBER_OF_SLOTS_BEFORE_NOW,
      slotsAfterNow: NUMBER_OF_SLOTS_AFTER_NOW,
    },
  }),

  for: (customerType: "relative" | "patient") => ({
    type: SET_CUSTOMER,
    payload: customerType,
  }),

  success: () => ({ type: SUBMIT_SURVEY_SUCCESS }),

  error: (e: string) => ({ type: SUBMIT_SURVEY_ERROR, payload: e }),

  submit: (answers: Answer[]) => {
    /*
     * Comply the answers object for API
     */
    const complyResponse = (wantedId: string): Value | undefined => {
      const responses: Value[] | undefined =
        answers.find(({ questionId }) => questionId === wantedId)?.value ||
        undefined
      if (!responses) return undefined
      const complied =
        responses.length > 1 ? responses.join(", ") : first(responses)

      return complied
    }

    const payload: ConsultationPayload = {
      chosen_doctor_id: complyResponse(PATH.doctor) || null,
      relative_id: complyResponse(PATH.relative),
      start_date: complyResponse(PATH.slot),
      medical: {
        illness: complyResponse(PATH.illness),
        illness_details: complyResponse(PATH.illness_details),
        allergies: complyResponse(PATH.allergy),
        allergies_details: complyResponse(PATH.allergy_details),
        operations: complyResponse(PATH.operation),
        pregnant: complyResponse(PATH.pregnant),
        drugs: complyResponse(PATH.drug),
        reason: complyResponse(PATH.reason),
      },
    }
    return { type: SUBMIT_SURVEY_REQUEST, payload }
  },
}

export default actions
