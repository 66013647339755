import { push } from "redux-first-history"
import config from "react-global-configuration"
import { AnyAction } from "redux"
import { all, AllEffect, call, fork, put, PutEffect, select, SelectEffect, take, takeLatest, takeLeading } from "redux-saga/effects"

import { CHECK_PHONE, REGISTER_PERSONAL_DATA, RETRIEVE_ACCOUNT, SURVEY_PAGE, VERIFY_PHONE } from "../../core/constants"
import { handleApiErrors, handleGenericApiError } from "../../lib/api-errors"
import { languages } from "../../lib/languages"
import { VALIDATION_STEP } from "../../types/contants"
import { genericPayload, Payload } from "../../types/payload"
import { Relative, ResponseLogin, ResponseVerify } from "../../types/types"
import { loginSuccess, manualRetrieveAccount } from "../authentication/actions"
import { setPatient, setRelatives, updateProspect } from "../client/actions"
import { getProspectNir } from '../client/selector'
import { getPropspectRelatives, getXProspectToken } from "../client/selector"
import { doctorListRequest } from "../doctor/actions"
import { getRelativesRequest } from "../relative/actions"
import { addError, flushError, setIsPasswordAdminValid, setVerifyModalLoadingState, verifyError, verifyModalNextStep, verifySuccess } from "./actions"
import { addRetry, setPhone } from "./actions"
import { CHECK_ADMIN_PASSWORD, VERIFY_CALL_REQUEST, VERIFY_REQUESTING } from "./constants"
import { BY_PASS_PHONE_VALIDATION, CANCEL_PHONE_VALIDATION, FIND_ACCOUNT_BY_PHONE_NUMBER, RESEND_CODE, SEND_CODE_BY_PHONE_CALL, VALIDATE_SMS_CODE } from "./constants"

const verifyUrl = "/api/customers/verify"
const sendCallUrl = "/api/customers/signup/next"

function* findAccountByPhoneNumber({ phone }: AnyAction) {
  const urlToCheckPhonePrefix = config.get(
    "clinic.routes.authentication.by_phone.check"
  )
  try {
    const phoneResponseStatusCode: number = yield fetch(
      `api${urlToCheckPhonePrefix}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
        },
        body: JSON.stringify({ phone }),
      }
    )
      .then((res) => {
        return res.status
      })
      .catch((error) => {
        throw error
      })

    if (phoneResponseStatusCode === 200) {
      yield put(setPhone(phone))
      yield all([
        put(manualRetrieveAccount({ type: "PHONE", value: phone })),
        put(push(RETRIEVE_ACCOUNT, {})),
      ])
    } else if (phoneResponseStatusCode === 404) {
      yield put(updateProspect({ phone }))
      yield put(
        push(REGISTER_PERSONAL_DATA, { step: VALIDATION_STEP.ENTER_PERSONAL_DATA })
      )
    }
  } catch (error) {
    console.error(error, {
      route: "verify::findAccountByPhoneNumber"
    })
  }
}

function* cancelPhoneValidation(): Generator<
  SelectEffect | PutEffect | Promise<Payload>,
  void,
  any
> {
  const xProspectToken: string = yield select(getXProspectToken)
  const cancelPhoneValidationUrl = config.get("clinic.routes.signup.cancel")

  const request: Promise<Payload> = fetch(`api${cancelPhoneValidationUrl}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": xProspectToken,
    },
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      console.error(error, {
        route: "verify::cancelPhoneValidation"
      })
    })

  try {
    const response = yield request
    if (response && response.statusCode === 200) {
      yield put(
        push(CHECK_PHONE, { step: VALIDATION_STEP.PHONE })
      )
    } else {
      throw languages.unableToCancelPhoneValidation
    }
  } catch (error) {
    console.error(error, {
      route: "verify::cancelPhoneValidation"
    })
  }
}

function* checkAdminPassword({
  password,
}: any): Generator<
  SelectEffect | PutEffect | Promise<genericPayload>,
  void,
  any
> {
  yield put(setVerifyModalLoadingState(true))
  const url = "api/admin/clinic/v1/customers/manual/verify/password"
  const xProspectToken: string = yield select(getXProspectToken)
  const request: Promise<genericPayload> = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": xProspectToken,
    },
    body: JSON.stringify({ code: password }),
  })
    .then(handleGenericApiError)
    .then((res) => res.json())
    .catch((error) => {
      throw error
    })

  try {
    const response = yield request

    if (response?.status === "ok") {
      const currentPath: string = yield select(
        (state) => state.router.location.pathname
      )
      yield put(flushError({ path: `${currentPath}-modal` }))
      yield put(setVerifyModalLoadingState(false))

      yield put(setIsPasswordAdminValid(true))
      yield put(verifyModalNextStep())
    } else {
      throw languages.invalidPassword
    }
  } catch (error) {
    yield put(setIsPasswordAdminValid(false))

    const currentPath: string = yield select(
      (state) => state.router.location.pathname
    )

    yield put(
      addError({
        path: `${currentPath}-modal`,
        error: {
          type: "error",
          text: languages.defaultPasswordSecretaryError,
        },
      })
    )
    yield put(setVerifyModalLoadingState(false))
  }
}

function formatPayload(payload: any) {
  const { insee_code, birth_country, birth_location, old_password, ...rest } = payload;
  return {
    ...rest,
    birth_location: insee_code && insee_code.toString() || "99999",
    firstname: rest.firstname ? rest.firstname : rest.first_birth_firstname,
    lastname: rest.lastname ? rest.lastname : rest.birth_lastname,
  }
}

function* addRelatives(
  relatives: any,
  patientToken: string,
  signup_by_carte_vitale: boolean = false
): Generator<Promise<Relative[]>, Promise<Relative[]>, Promise<Relative[]>> {

  const relativesUrl = config.get("clinic.routes.relatives")
  const promiseList = relatives.map((relative: any) => {
    let formattedRelative = formatPayload(relative);
    console.log('patientToken', patientToken);
    return fetch(`api${relativesUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
        "X-Patient-Token": patientToken,
      },
      body: JSON.stringify({ ...formattedRelative, signup_by_carte_vitale }),
    })
      .then(handleGenericApiError)
      .then((res) => {
        return res.json()
      })
      .catch((error) => console.error(error, {
        route: "verify::addRelatives"
      }))
  }
  )

  return yield Promise.all(promiseList).then((res) => {
    let cleanData: any = []
    res.forEach((r: any) => {
      if (r && r.status === "ok") {
        cleanData.push(r.relative)
      }
    })
    return cleanData
  })
}

function* lockIdentityComingFromVitalCard(nir: string, patientToken: string): any {
  console.log('sessionStorage.getItem("X-USER-Token")', sessionStorage.getItem("X-USER-Token"));
  console.log('patientToken', patientToken);
  yield fetch(`api/customers/secured`, {
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": patientToken,
    },
    method: "PUT",
    body: JSON.stringify({ nir, signup_by_carte_vitale: true })
  }).catch((error) => {
    throw error;
  })
}

function* validateSMSCode({
  code,
}: any): Generator<
  | PutEffect
  | AllEffect<PutEffect>
  | SelectEffect
  | Generator<Promise<Relative[]>, Promise<Relative[]>, Promise<Relative[]>>
  | Promise<Payload>,
  void,
  any
> {
  const xProspectToken: string = yield select(getXProspectToken)
  const validateSMSUrl = config.get("clinic.routes.signup.verify")

  const request: Promise<Payload> = yield fetch(`api${validateSMSUrl}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": xProspectToken,
    },
    body: JSON.stringify({ code }),
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      throw error
    })

  const currentPath: string = yield select(
    (state) => state.router.location.pathname
  )

  try {
    const response = yield request
    if (response && response.statusCode === 200) {
      let { body } = response
      const patientToken = response.headers["x-patient-token"]
      console.log('patientToken', patientToken);

      if (typeof body === "string") {
        body = JSON.parse(body)
      }
      const fromNir = yield select(({ client }) => client.prospect?.fromNir);
      console.log('fromNir', fromNir);
      if (fromNir) {
        const nir = yield select(getProspectNir)
        yield lockIdentityComingFromVitalCard(nir, patientToken);
      }
      let relatives: Relative[] = yield select(getPropspectRelatives)
      if (relatives.length > 0) {
        relatives = yield addRelatives(relatives, patientToken, fromNir)
      }
      yield all([
        put(loginSuccess(body as ResponseLogin)),
        put(setPatient({ ...body.customer, token: patientToken })),
        put(doctorListRequest()),
        put(setRelatives(relatives)),
      ])
      yield put(push(SURVEY_PAGE))
    } else if (response && response.statusCode === 404) {
      throw languages.invalidCode
    }
  } catch (error) {
    console.error('errror : ', error);
    yield put(
      addError({
        path: currentPath,
        error: {
          type: "error",
          text: error as string,
        },
      })
    )
  }
}

function* sendCodeByPhoneCall(): Generator<
  SelectEffect | Promise<number> | PutEffect,
  void,
  any
> {
  const senCodeByPhoneCallUrl = config.get("clinic.routes.signup.next")
  const xProspectToken: string = yield select(getXProspectToken)

  const request: Promise<number> = fetch(`api${senCodeByPhoneCallUrl}`, {
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": xProspectToken,
    },
    method: "PUT",
  })
    .then(handleGenericApiError)
    .then((res) => res.status)
    .catch((error) => {
      throw error
    })

  const currentPath: string = yield select(
    (state) => state.router.location.pathname
  )
  yield put(addRetry("phone"))

  try {
    const statusCode = yield request
    if (statusCode && statusCode === 200) {
      yield put(
        addError({
          path: currentPath,
          error: { type: "info", text: languages.youAreGoingToGetACall },
        })
      )
      yield put(
        push(VERIFY_PHONE, { step: VALIDATION_STEP.VERIFY_PHONE })
      )
    } else if (statusCode && statusCode === 404) {
      throw languages.errorOccurredWheSendingCode
    }
  } catch (error) {
    yield put(
      addError({
        path: currentPath,
        error: {
          type: "error",
          text: error as string,
        },
      })
    )
  }
}

function* resendCodeBySMS(): Generator<
  SelectEffect | PutEffect | Promise<Payload>,
  void,
  any
> {
  const senCodeByPhoneCallUrl = config.get("clinic.routes.signup.next")
  const xProspectToken: string = yield select(getXProspectToken)

  const request: Promise<Payload> = fetch(`api${senCodeByPhoneCallUrl}`, {
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": xProspectToken,
    },
    method: "PUT",
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      console.error(error, {
        route: `api${senCodeByPhoneCallUrl}`
      })
    })

  const currentPath: string = yield select(
    (state) => state.router.location.pathname
  )
  yield put(addRetry("phone"))

  try {
    const response = yield request
    if (response && response.statusCode === 200) {
      yield put(
        addError({
          path: currentPath,
          error: { type: "info", text: languages.code_has_been_send_again },
        })
      )
      yield put(push(VERIFY_PHONE, { step: VALIDATION_STEP.VERIFY_PHONE }))
    } else if (response && response.statusCode === 404) {
      throw languages.errorSendingCode
    }
  } catch (error) {
    yield put(
      addError({
        path: currentPath,
        error: {
          type: "error",
          text: error as string,
        },
      })
    )
  }
}

function* byPassPhoneValidation(payload: any): any {
  yield put(setVerifyModalLoadingState(true))
  const byPassPhoneValidationUrl = config.get("clinic.routes.signup.manual")
  const xProspectToken: string = yield select(getXProspectToken)
  const { phone, password } = payload
  const request: Promise<Payload> = fetch(`api${byPassPhoneValidationUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
      "X-Patient-Token": xProspectToken,
    },
    body: JSON.stringify({ code: password, phone }),
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      throw error
    })

  try {
    const response = yield request

    if (response && response.statusCode === 200) {
      let { body } = response
      const patientToken = response.headers["x-patient-token"]

      if (typeof body === "string") {
        body = JSON.parse(body)
      }

      const fromNir = yield select(({ client }) => client.prospect?.fromNir);
      if (fromNir) {
        const nir = yield select(getProspectNir)
        yield lockIdentityComingFromVitalCard(nir, patientToken);
      }

      let relatives: Relative[] = yield select(getPropspectRelatives)
      if (relatives.length > 0) {
        relatives = yield addRelatives(relatives, patientToken, fromNir)
      }
      yield put(setVerifyModalLoadingState(false))
      yield all([
        put(loginSuccess(body as ResponseLogin)),
        put(setPatient({ ...body.customer, token: patientToken })),
        put(setRelatives(relatives)),
        put(doctorListRequest()),
      ])
      yield put(push(SURVEY_PAGE))
    } else {
      throw languages.unableToValidatePhone
    }
  } catch (error) {
    const currentPath: string = yield select(
      (state) => state.router.location.pathname
    )
    yield put(
      addError({
        path: `${currentPath}-modal`,
        error: { type: "error", text: error as string },
      })
    )
    yield put(setVerifyModalLoadingState(false))
  }
}

function verifyApi(phone: string, code: string) {
  return fetch(verifyUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
    body: JSON.stringify({ phone, code }),
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error
    })
}

function sendCallApi() {
  return fetch(sendCallUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
  })
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error
    })
}
function* verifyFlow(phone: any, code: any) {
  try {
    const response: ResponseVerify = yield call(verifyApi, phone, code)
    yield all([
      put(setPatient(response.customer)),
      put(doctorListRequest()),
      put(getRelativesRequest()),
      put(verifySuccess(response)),
    ])
    if (response.customer) yield put(setPatient(response.customer))
  } catch (error) {
    // error? send it to redux
    yield put(verifyError(error as string))
  }
}
function* sendCallFlow() {
  try {
    yield call(sendCallApi)
  } catch (error) {
    yield put(verifyError(error as string))
  }
}

function* verifyWatcher() {
  yield takeLatest(BY_PASS_PHONE_VALIDATION, byPassPhoneValidation)
  yield takeLatest(RESEND_CODE, resendCodeBySMS)
  yield takeLatest(SEND_CODE_BY_PHONE_CALL, sendCodeByPhoneCall)
  yield takeLatest(VALIDATE_SMS_CODE, validateSMSCode)
  yield takeLatest(CANCEL_PHONE_VALIDATION, cancelPhoneValidation)
  yield takeLatest(FIND_ACCOUNT_BY_PHONE_NUMBER, findAccountByPhoneNumber)
  yield takeLeading(CHECK_ADMIN_PASSWORD, checkAdminPassword)
  while (true) {
    const action: {
      type: string
      payload: { phone: string; code: string | number }
    } = yield take([VERIFY_REQUESTING, VERIFY_CALL_REQUEST])
    if (action.type === VERIFY_REQUESTING) {
      yield fork(verifyFlow, action.payload.phone, action.payload.code)
    }
    if (action.type === VERIFY_CALL_REQUEST) {
      yield fork(sendCallFlow)
    }
  }
}

export default verifyWatcher
