import LocalizedStrings, { LocalizedStringsMethods } from "react-localization"

export interface IStrings extends LocalizedStringsMethods {
  [key: string]: any
}

export const languages: IStrings = new LocalizedStrings({
  fr: {
    //@TODO push page names here
    modifyYour: (name: string) => {
      return "Modifiez votre " + languages[name]
    },
    whatDoYouWantToDo: "Que souhaitez-vous faire ?",
    newRDV: "Nouveau RDV",
    changeDoctor: "Changer de praticien",
    cancelConsultation: "Annuler ma consultation",
    selectADoctor: "Selectioner un professionel de santé",
    modify: "modifier",
    verifyYourInformations: "Voici les informations récupérées de votre carte vitale",
    finish: "Terminer",
    yourPasswordNeeds: "Votre mot de passe doit contenir au moins :",
    height_char: "10 caractères",
    a_lower_letter: "Une lettre minuscule",
    a_upper_letter: "Une lettre majuscule",
    a_number: "Un chiffre",
    page_cgu: "condition-general-d-utilisation",
    page_privacy: "donnees-personnelles",
    page_retrieve_account: "retrouver-son-compte",
    page_home: "home",
    page_welcome: "bienvenue",
    page_register: "inscription",
    page_login: "connexion",
    page_order: "questions",
    page_admin: "administrateur",
    page_not_authorized: "401",
    page_not_found: "404",
    page_verify: "verification",
    page_insert_relative: "creation-de-proche",
    page_nir: "carte-vitale",
    page_lobby: "authentication",
    page_check_phone: "validation-du-telephone",
    page_check_lastname: "validation-du-nom",
    page_check_password: "validation-du-mot-de-passe",
    page_register_personal_data: "saisie-des-donnees-personnelles",
    page_verify_phone: "verification-du-mobile",
    page_register_mail: "saisie-du-mail",
    page_maintenance: "maintenance",
    page_is_this_your_account: "verification-compte-existant",
    page_update_relative: "selection-genre-du-relatif",
    defaultReplyWhichWho: "L'un de nos médecins disponible",
    touch_screen: "Touchez l'écran pour démarrer",
    enter_phone: "Connexion / inscription - Compte Medadom",
    enter_password:
      "Définissez un mot de passe, \n\r pour la création de votre compte Medadom",
    enter_lastname: "Veuillez saisir votre nom de famille",
    re_enter_phone: "Corriger mon numéro",
    re_enter_nir: "Vérifiez de nouveau les informations",
    contact_secretary:
      "En cas de question, veuillez contacter le secrétariat médical",
    panel_for_secretary: "Mot de passe secrétariat médical",
    resend_code: "Renvoyer le code par SMS",
    code_has_been_send_again: "le code a été renvoyé",
    code_insee: "Lieu / INSEE de naissance",
    ask_for_voiceCall: "Recevoir le code par appel téléphonique",
    ask_for_phone_re_enter:
      "Merci de rentrer de nouveau le téléphone du patient",
    verification_of: "Vérification du",
    welcome_to_medadom: "Bienvenue sur Medadom !",
    registery_will_take_3_minutes:
      "Votre pré-inscription ne vous prendra que 3 minutes",
    nirReaderInProgress: "Lecture en cours...",
    openKeyboard: "Ouvrir le clavier",
    // LOGIN / REGISTER / ADMIN
    inputYour: "Merci d'entrer votre ",
    birthDateRelative: "Merci d'entrer le lieu de naissance du proche",
    incorrectInput: `Merci d'entrer un champs valide pour`,
    addPhoneNumber: "L'ajouter",
    password: "Mot de passe",
    gender: "Genre",
    phone: "Téléphone portable",
    email: "e-mail",
    nir: "Numéro de carte vitale",
    nirSpacer: " / ",
    nirMaskChar: "_",
    nirAllowedChar: "*",
    female: "Femme",
    birthdate: "Date de naissance",
    lastname: "Nom utilisé au quotidien",
    birth_lastname: "Nom de naissance",
    firstname: "Prénom utilisé au quotidien",
    civility: "Civilité",
    male: "Homme",
    loginTitle: "J'ai déjà un compte client",
    login: "Se connecter",
    loginNir: "Valider",
    inputRelativeBirthdate: "Merci d'entrer la date de naissance du proche",
    inputRelativeNir:
      "Saisissez le numéro de Carte Vitale du bénéficiaire des droits",
    inputRelativeGender: "Merci d'indiquer le genre du proche",
    inputRelativeFirstname: "Merci d'indiquer le prénom du proche",
    inputRelativeLastname: "Merci d'indiquer le nom du proche",
    invalid_password: "Mot de passe invalide",

    previous: "Retour",
    registerTitle: "Page d'inscription",
    register: "S'inscrire",
    needHelp: "Besoin d'aide ?",
    sendCode: "Envoyer le code",
    smsNotReceived: "Je n'ai pas reçu le sms",
    alreadyAcount: "J'ai déjà un compte patient Medadom",
    // ERRORS
    defaultErrorForm: "Une erreur s'est glissée dans le formulaire",
    mismatchingErrorForm: "Les identifiants ne correspondent pas",
    phoneExistingErrorForm: "Le numéro de téléphone n'est pas disponible",
    phoneNotFound: "Le numéro de téléphone n'existe pas dans notre base",
    payloadErrorForm: "Une erreur s'est glissée dans la requête",
    TooManyRequest: "Trop de requêtes. Veuillez réessayer ultérieurement",
    errorLogin: "Une erreur s'est glissée dans le formulaire",
    existingOrderError: "La téléconsultation existe déjà",
    nirInvalid: "Le numéro de carte vitale n'est pas valide",
    dateInvalid: "La date de naissance est invalide",
    phoneInvalid: "Le téléphone n'est pas valide",
    nirLengthError: "Le numéro de carte vitale doit contenir 15 chiffres",
    nexmoError: `Le code de vérification est erroné`,
    createAccountError: `Une erreur est survenue lors de la création du compte`,
    adminInformationWelcome:
      "L’équipe Medadom vous a transmis par mail, lors de votre inscription, votre identifiant ainsi que votre mot de passe. Merci de vous connecter ici avec ces identifiants. Contactez nous au 01 86 65 55 15 en cas de problème.",
    birthdatePlaceholder: "JJ / MM / AAAA",
    writeVerifyCode: (phone: string | null = null) =>
      `Entrez le code reçu  au ${phone ? phone : ""}`,
    inputThe: (name: string) => {
      if (["birthdate"].includes(name)) return "Entrez la " + languages[name]
      else return "Entrez le " + languages[name]
    },
    callMeVerify: "Recevoir un appel pour le code",
    defaultExtraInputPhone: "Un SMS vous sera envoyé pour confirmer ce numéro.",
    defaultPlaceHolderInputPhone: "06 XX XX XX XX",
    reEnterPreviousPhone: "Merci d’entrer le numéro de téléphone du patient",
    phoneMismatch:
      "Le numéro est différent de celui utilisé pour l’inscription",
    invalidPassword: "Le mot de passe renseigné n'est pas valide",
    defaultPasswordSecretaryError:
      "Une erreur est survenue lors de la validation du mot de passe",
    errorSendingCode: "Une erreur est survenue lors de l'envoi du code",
    unableToValidePhone: "Veuillez contacter le secrétariat",
    unableToCancelPhoneValidation: "Impossible d'annuler l'inscription",
    invalidCode: "Le code est invalide",

    jan: "Janvier",
    feb: "Février",
    mar: "Mars",
    apr: "Avril",
    may: "Mai",
    jun: "Juin",
    jul: "Juillet",
    aug: "Août",
    sep: "Septembre",
    oct: "Octobre",
    nov: "Novembre",
    dec: "Décembre",
    optional: "(Optionnel)",
    next: "Suivant",
    medikSante: "Centre de santé médiksanté",
    partnerOf: "Partenaire de",
    selectDayBirthdate: "Jour",
    selectMonthBirthdate: "Mois",
    selectYearBirthdate: "Année",
    currentlyReadingNir: "\tLecture en cours...",
    IHaveNoAccount: "Je n'ai pas <b style='font-weight:normal'>de compte</b>",
    IHaveAccount: "J'ai déjà <b style='font-weight:normal'>un compte</b>",
    doYouHaveAnAccount: "Avez-vous un compte Medadom",
    DoYouHaveANir: "Avez-vous une carte vitale ?",
    DoYouKnowYourNir: "Connaissez-vous votre numéro de sécurité sociale ?",
    writeNir: "Inscrivez vos numéros",
    insertYourNir: "Insérez votre carte vitale jusqu'au bout du lecteur",
    removeYourNir: "Retirez votre carte vitale pour passer à la suite",
    nirManualInput: "Je n'ai pas ma carte vitale",
    tryAgain: "Relancer le lecteur",
    ReaderStopped: "Le lecteur s'est arrêté",
    readerSocketGenericError:
      "Une erreur est survenue sur le lecteur de carte, \r\n Veuillez le saisir manuellement",
    readerSocketReadError:
      "Une erreur est survenue lors de la lecture de votre carte",
    nirReaderError: "Nous n'avons pas pu récupérer les informations",
    continue: "Continuer",
    validate: "Étape suivante",
    orderFinal:
      "Merci, récupérez votre carte vitale et allez au secrétariat pour finaliser votre rendez-vous",
    relative: "proche",
    credit: "© 2021 MEDADOM Tous droits réservés",
    addRelative: "Ajouter le proche",
    enterRelativeInformations: "Le proche doit renseigner ses informations",
    wrongNirLength: "Veuillez saisir 15 chiffres",
    change_phone: "Changer de numéro",
    yes: "OUI",
    no: "NON",
    logout: "déconnexion",
    accountFound: "Votre compte patient a été reconnu",
    inactivity: "Vous allez être déconnecté pour inactivité",
    youAreGoingToGetACall: "Vous allez recevoir un appel",
    errorOccurredWheSendingCode:
      "Une erreur est survenue lors de l'envoi du code",
    toCreateYourAccount: "Pour la création de votre compte MEDADOM",
    provideYourPassword: "Définissez un mot de passe",
    provideYourMail:
      "Merci de saisir votre e-mail, pour réception des documents médicaux",
    threeCharsMinLenght: "Merci de bien vouloir entrer 3 caractères minimum",

    //order
    inform_anamnesis_relative:
      "Le professionnel de santé a besoin de certaines informations médicales concernant la/le proche",
    inform_anamnesis:
      "Le professionnel de santé a besoin de certaines informations médicales vous concernant",
    questionForWho: "Pour qui est la consultation ?",
    orderForWhichDoctor: "Avec quel professionnel de santé avez-vous rendez-vous ?",
    orderForWhichDoctor_relative:
      "Avec quel professionnel de santé votre proche a-t-il/elle rendez-vous ?",
    orderForWhichHour: "À Quelle heure avez-vous rendez-vous ?",
    orderForWhichHour_relative:
      "À Quelle heure votre proche a-t-il/elle rendez-vous ?",
    questionWhichRelative: "Pour quel proche ?",
    questionWhichTime: "À quelle heure est programmé le rendez-vous ?",
    forMe: "Pour moi",
    forRelative: "Pour un proche",
    noAppointment: "Je n'ai pas de rendez-vous",
    noAppointment_relative: "Mon proche n'a pas de rendez-vous",
    me: "moi",
    addARelative: "Ajouter un proche",
    byPassEmail: "Je n'ai pas d'e-mail",
    byPassNir: "Je n'ai pas de carte vitale",
    IHaveNoEmail: "J'atteste ne pas avoir d'adresse e-mail",
    checkNoEmail:
      "Merci de ne cocher cette case que si vous n'avez pas d'adresse mail valide.",
    IAcceptConsent: "J'accepte",
    IRefuseConsent: "Je ne souhaite pas poursuivre",
    consentTitleModal: "MEDADOM protège vos informations",
    noEmailTitleModal: "Adresse e-mail obligatoire",
    noEmailContentModal: `En renseignant votre adresse email : <br /><br /> <img src="/images/check.svg" class="checkNoEmail" alt="check" /> Vous pourrez recevoir des informations sur les documents médicaux liés à votre consultation <br /><br /> <img src="/images/check.svg" class="checkNoEmail" alt="check" /> Il sera plus facile de vous joindre en cas de quelconque question`,
    anamneseIllnessQuestion: "Avez-vous déjà eu des maladies chroniques ?",
    anamneseIllnessSubtitle: "(diabète, hypertension, ...)",
    anamneseIllnessQuestion_relative:
      "Votre proche a-t-il/elle déjà eu des maladies chroniques ?",
    anamneseIllnessQuestionDetails: "Quelle(s) maladie(s) ?",
    diabetes: "diabète",
    hypertension: "hypertension",
    cancer: "cancer",
    respiratoryDisease: "maladie respiratoire",
    heartDisease: "maladie cardiaque",
    kidneyDisease: "maladie rénale",
    digestiveDisease: "maladie digestive",
    infectiousDisease: "maladie infectieuse",
    otherDisease: "autre",
    account_not_found: "Patient non trouvé",
    anamneseAllergiesQuestion: "Avez-vous des allergies connues ?",
    anamneseAllergiesQuestion_relative:
      "Votre proche a-t-il/elle des allergies connues ?",
    anamneseAllergiesSubtitle: "(pollen, médicamenteuse, ...)",
    anamneseAllergiesQuestionDetails: "Quelle(s) allergie(s) ?",
    drugAllergy: "allergie médicamenteuse",
    pollenAllergy: "allergie au pollen",
    foodAllergy: "allergie alimentaire",
    otherAllergy: "autre",

    cancel: "Annuler",
    codeAllowedChar: "9",
    codeSpacer: " ",
    anamneseOperationsQuestion: "Avez-vous déjà été opéré(e) ?",
    anamneseOperationsQuestion_relative:
      "Votre proche a-t-il/elle déjà été opéré(e) ?",
    anamneseDrugsQuestion: "Prenez-vous régulièrement des médicaments ?",
    anamneseDrugsQuestion_relative:
      "Votre proche prend-il/elle régulièrement des médicaments ?",
    anamnesePregnantQuestion: "Êtes-vous enceinte ?",
    anamnesePregnantQuestion_relative: "Votre proche est-elle enceinte ?",
    authent_birthdate:
      "Merci d'indiquer votre date de naissance pour vous authentifier",
    authent_lastname:
      "Merci d'indiquer votre nom de famille pour vous authentifier",
    authent_failed: "Nous avons reconnu votre compte, mais certaines informations ne semblent pas correspondre",
    authent_failed_hint: "Merci d'appeler le secrétariat afin de terminer l'enregistrement",
    backToHome: "Retour à l'accueil",

    select_reason: "Selectionnez un motif ...",
    anamneseReasonQuestion: "Quel est le motif de votre consultation ?",
    anamneseReasonQuestion_relative:
      "Quel est le motif de consultation de votre proche ?",
    covid: "Coronavirus (Covid 19)",
    allergy: "Allergies",
    burn: "Brûlure",
    contraception: "Contraception",
    cystitis: "Cystite",
    jointpain: "Douleurs articulaires (genou, poignet)",
    jointpainWithoutParenthesis: "Douleurs articulaires",
    diarrhea_or_vomiting: "Diarrhée ou vomissement",
    toothpain: "Douleurs dentaires",
    back_pain: "Douleurs lombaires",
    rash: "Éruption cutanée",
    tired: "Fatigue / anxiété / stress",
    fever: "Fièvre",
    sexual_infection: "Infection sexuelle",
    sore_throat: "Maux de gorge",
    headache: "Maux de tête",
    stomach_ache: "Maux de ventre",
    eye_pain: "Œil rouge et/ou douleur",
    sting: "Piqûre / morsure / blessure",
    otitis: "Oreilles/otite",
    prescription_refill: "Renouvellement d'ordonnance",
    period_pain: "Règles douloureuses",
    lab_results: "Résultats de laboratoire",
    cold_and_flu: "Rhume et syndrome grippal",
    sinus: "Sinus ou nez bouché",
    cough: "Toux",
    otherReason: "Autre",
    isThisYourAccount: "Votre compte est-il rattaché au",
    yesComma: "Oui,",
    itIsMyAccount: "j'utilise ce compte",
    itIsNotMyAccount: "Non, je souhaite créer un nouveau compte",
    signupKidWarning: "Si vous accompagnez votre enfant, inscrivez-vous d'abord pour la création de votre compte",
    signupKidInfo: "Vous aurez la possibilité d'ajouter votre enfant en tant que proche ultérieurement dans le parcours.",
    doSignup: "Je m'inscris",
    understood: "J'ai compris",
    iAmMinor: "Je suis mineur",
    minorSignupTitle: "Inscription d'un mineur",
    minorSignupText: "Si vous venez consulter pour votre enfant, inscrivez-vous d'abord pour la création de votre compte. Vous aurez la possibilité d'ajouter votre enfant en tant que proche ultérieurement dans le parcours.",
    DD: "JJ",
    MM: "MM",
    YYYY: "AAAA",
    errorDate_shouldBeOlderThan: (minAge?: number) =>
      `Vous devez avoir plus de ${minAge || 3} ans.`,
    errorDate_shouldBeYoungerThan: (maxAge?: number) =>
      `La téléconsultation n'est pas possible après ${maxAge || 110} ans`,
    man: "Homme",
    woman: "Femme",
    generalist: "Médecin Généraliste",
    secretary: "Secrétaire",
    midwife: "Sage-Femme",
    useAnother: (name: string) => `J'utilise au quotidien un autre ${name}`,
    relativeUseAnother: (name: string) => `le proche utilise au quotidien un autre ${name}`,
    unknown: "Inconnu",
    unknownBirthLocation: "Lieu de naissance inconnu",
    foreign: "Étranger",
    french: "France",
    birth_location: "Lieu de naissance",
    bornInFrance: "Je suis né(e) en France ?",
    notBornInFrance: "Je ne suis pas né(e) en France ?",
    relativeNotBornInFrance: "Le proche n'est pas né(e) en France ?",
    mustContainLessThanHundredCharacters: "Le champ doit contenir moins de 100 caractères",
    cantContainSpaceAsFirstCharacters: "Le premier caractère ne peut être un espace ou tiret",
    cantContainTwoConsecutiveSpace: "Le champ ne peut contenir deux espaces consécutif",
    cantContainTwoConsecutiveApostrophe: "Le champ ne peut contenir deux apostrophe consécutif",
    cantContainTwoConsecutiveHyphen: "Le champ ne peut contenir deux tirer consécutif",
    cantContainHyphenFollowedByApostrophe: "Le champ ne peut contenir un tiret suivi d'un apostrophe ",
    cantContainHyphenFollowedBySpace: "Le champ ne peut contenir un tiret suivi d'un espace",
    cantFinishWithHyphenOrApostrophe: "Le champ ne peut pas finir par un tiret ou un apostrophe",
    shouldStartWithAlphaOrApostrophe: "Le champ doit commencer par un caractère ou un apostrophe",
    shouldContainAtLeastOneAlphaCharacter: "Le champ doit contenir au moins un caractère alphanumérique",
    shouldNotContainSlash: "Le champ ne peut contenir de slash",
    locationError: "Veuillez renseigner un lieu valide",
    locationInvalid: "Veuillez selectionner un lieu de naissance",
    locationAddress: "Lieu de naissance",
    locationAddressError: "Lieu de naissance invalide",
    inputYourBirthCountry: "Entrez votre pays de naissance",
    inputYourBirthCity: "Entrez votre ville de naissance",
    inputYourRelativeBirthCity: "Entrez la ville de naissance du proche",
    inputYourRelativeBirthCountry: "Entrez le pays de naissance du proche",
    unknwonBirthPlace: "Passez à l'étape suivante",
    chooseYourNationality: "Choisissez votre lieu de naissance",
    city: "ville",
    birthCity: "ville de naissance",
    birthCountry: "pays de naissance",
    birthLocation: "ville de naissance",
    select_birth_location: "Lieu de naissance",
    first_birth_firstname: "1er Prénom de naissance",
    first_birth_firstname_relative: "Merci d'entrer le 1er Prénom de naissance du proche",
    birth_lastname_relative: "Merci d'entrer le nom de naissance du proche",
    nurse: "Infirmier(ère)",
    change_has_been_made: "Votre changement a bien été pris en compte",
    go_to_the_secretary: `Veuillez vous rapprocher du secrétariat, muni(e) de votre carte vitale`,
    modifyOrAddAnAppointment: "veuillez modifier ou ajouter un rendez-vous",
  },
  en: {},
})
languages.setLanguage("fr")
